<template>
  <div class="welcome container">
    <div v-if="showLogin">
      <LoginForm @login="enterAll"/>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: { LoginForm },
  setup() {
    const showLogin = ref(true)
    const router = useRouter()
    const enterAll = () =>{
      router.push({ name: 'Home'})
    }
    return { showLogin, enterAll }

  }
}
</script>

<style >
  .welcome {
    text-align: center;
    padding: 20px 0;
  }
  /* form styles */
  .welcome form {
    width: 300px;
    margin: 20px auto;
  }
  .welcome label {
    display: block;
    margin: 20px 0 10px;
  }
  .welcome input {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #eee;
    outline: none;
    color: #999;
    margin: 10px auto;
  }
  .welcome span{
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
  .welcome button {
    margin: 20px auto;
  }
</style>