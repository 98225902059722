import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCollection = (collection) => {

  const documents = ref(null)
  const error = ref(null)

  // firestore collection のリファレンスを登録する
  let collectionRef = projectFirestore.collection(collection)
    .orderBy('name.last')

  const unsub = collectionRef.onSnapshot(snap => {
    console.log(snap)
    let results = []
    snap.docs.forEach(doc => {
      // サーバーがタイムスタンプを作成して，送り返してくるまで待つ
      // タイムスタンプができて，送り返してくるまで編集しない
      doc.data().createdAt && results.push({...doc.data(), id: doc.id})
    });
    // update values
    documents.value = results
    error.value = null
  }, err => {
    console.log(err.message)
    documents.value = null
    error.value = 'could not fetch the data'
  })

  watchEffect((onInvalidate) => {
    // unsub from prev collection when watcher is stopped (component unmounted)
    onInvalidate(() => unsub());
  });

  return { error, documents }
}

export default getCollection