<template>
  <header>
    <!-- ロゴ -->
    <h1><a href="/">
        <img class="logo" src="../assets/logo.png" alt="members">
    </a>
    </h1>
    <!-- タイトル -->
    <nav>
      <ul class="main-nav">
      <router-link :to="{ name: 'Home' }"><span class="material-icons">view_module</span></router-link>
      <div v-if="user">     
        <a v-if="user" class="material-icons"><span>account_circle</span> {{ user.displayName }}</a>
        <a @click="handleClick"><span class="material-icons">logout</span></a>
      </div>
      <div v-else>
        <router-link class="btn" :to="{ name: 'Welcome' }"><span class="material-icons">login</span></router-link>
      </div>      
      </ul>
    </nav>
    
  </header>
</template>

<script>
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'


export default {
  setup() {
    const { logout, error } = useLogout()
    const { user } = getUser()

    const handleClick = async () => {
      await logout()
      if (!error.value) {
        console.log('user logged out')
      }
    }
    return { handleClick, user }
  }
}
</script>

<style scoped>
  .logo {
    width: 50px;
    height: 40px;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 10px auto;
    padding: 10px;
  }
  header h1 a {
    color: #444;
    font-size: 20px;
  }
  header a {
    color: #bbb;
    text-decoration: none;
    margin-left: 20px;
    cursor: pointer;
    float: left;

  }

  header p {
    color: #bbb;
    text-decoration: none;
    margin-left: 20px;
  }

  header button {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
    float: left;
  }

  .main-nav{
    display: flex;
    font-size: 1rem;
    text-transform: uppercase;
  }

  header nav a.router-link-active {
    color: #444;
    font-weight: bold;
    float: left;
  }

  a.material-icons{
    color: #bbb;
    font-size: 20px;
  }
  a.material-icons:hover{
    color: #bbb;
    cursor: default;
  }
</style>


