<template>

<div class="container">
  <div class="form-group">
      <form @submit.prevent="handleSubmit">

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Last Name: <span class="badge badge-info">*</span></label>
            <input v-model="last" type="text" class="form-control" required>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label">First Name: <span class="badge badge-info">*</span></label>
            <input v-model="first" type="text" class="form-control" required>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label">Middle Name: </label>
            <input v-model="middle" type="text" class="form-control" >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>姓: </label>
            <input v-model="lastJa" type="text" class="form-control">
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label">名: </label>
            <input v-model="firstJa" type="text" class="form-control">
          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-sm-3">
            <label class="form-label">フリガナ(姓): </label>
            <input v-model="phoneticsLast" type="text" class="form-control" >
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label">フリガナ(名): </label>
            <input v-model="phoneticsFirst" type="text" class="form-control" >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label class="form-label">社員コード: <span class="badge badge-info">*</span></label>
            <input v-model="employeeId" type="text" class="form-control" required>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label class="form-label">所属: (エンターを押して複数登録)</label>
            <input @keydown.enter.prevent="handleKeydown" v-model="teamTag" type="text" class="form-control-file">
          </div>
        </div>

        <div v-for="teamTag in teamTags" :key="teamTag" class="badge badge-pill badge-info">
          {{ teamTag }}
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label class="form-label">役職: </label>
            <input v-model="role" type="text" class="form-control" >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label class="form-label">Profile Photo(顔写真): <span class="badge badge-info">正方形の画像にしてください。</span></label>
            <input type="file" @change="handleChange" class="form-control-file" required>
          </div>
          <div class="error">{{ fileError }}</div>
        </div>

        <button class="btn">投稿</button>
        
      </form>

    </div>

</div>

</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import getUser from '@/composables/getUser'
import useStorage from '../composables/useStorage'

import  { projectFirestore, timestamp } from '@/firebase/config'

import useCollection from '@/composables/useCollection'

export default {
  setup(){
    // Formの変数
    const last = ref('')
    const lastJa = ref('')
    const first = ref('')
    const firstJa = ref('')
    const middle = ref('')
    const phoneticsLast = ref('')
    const phoneticsFirst = ref('')
    const employeeId = ref('')
    const role = ref('')

    //所属タグ
    const teamTags = ref([])
    const teamTag = ref('')

    const { user } = getUser()
    const router = useRouter()

    const handleKeydown = () => {
      if (!teamTags.value.includes(teamTag.value)) {
        teamTag.value = teamTag.value.replace(/\s/g,'') // remove all whitespace
        teamTags.value.push(teamTag.value)
      }
      teamTag.value = ''
    }


    // ファイルのアップロード
    const { filePath, url, uploadProfilePhoto } = useStorage()
    const file = ref(null)
    const fileError = ref(null)

    //投稿
    const { addDoc, error } = useCollection('members')

    //handleSubmit
    const handleSubmit = async () => {

      // ファイルも投稿
      await uploadProfilePhoto(file.value, employeeId)

      const userInfo = {
        name: {
          last: last.value,
          first: first.value,
          middle: middle.value,
          lastJa: lastJa.value,
          firstJa: firstJa.value,
          phoneticsLast: phoneticsLast.value,
          phoneticsFirst: phoneticsFirst.value,
          },
        employeeId: employeeId.value,
        role: role.value,
        teamTags: teamTags.value,
        
        // teamTags: teamTags.value,
        fileUrl: url.value,
        filePath: filePath.value,
        createdAt: timestamp()
        // createdBy: user.displayName
      }

      const res = await addDoc(userInfo)
      router.push({ name: 'Home' })

      if(!error.value){
        message.value = ''
      } 
  
    }

    // allowed file types
    const types = ['image/png', 'image/jpeg']
    const handleChange = (e) => {
      let selected = e.target.files[0]
      console.log(selected)
      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Please select an image file (png or jpg)'
      }
    }

    // if the user value is ever null, redirect to welcome screen
    watch(user, () => {
      if (!user.value) {
        router.push({ name: 'Welcome' })
      }
    })


    return { 
      user, 
      last, first, middle, lastJa, firstJa, phoneticsLast, phoneticsFirst
    , employeeId
    , role
    , teamTags, teamTag
    , handleKeydown, handleSubmit, fileError, handleChange  }
  }
}
</script>

<style scoped>
.btn {
  color: #fff;
  background-color: #41A7C5;
  border-color: #41A7C5;
}

.btn:hover {
  color: #fff;
  background-color: #285B7E;
  border-color: #41A7C5;
}


</style>