<template>
  <div class="memberInfo">
    <div class="row">
      <div class="col">
       {{ member.name.last }} {{member.name.first}}
      </div>
      <div class="col">
       {{ member.name.lastJa }} {{member.name.firstJa}}
      </div>
      <div class="col">
        3 of 3
      </div>
    </div>
    <div class="row">
      <div class="col">
       <span @click="handleClick(member.id)" class="material-icons delete">delete</span>
      </div>
    </div>    
  </div>

  

  <!-- <div v-else>
    <Spinner />
  </div> -->

</template>

<script>
import getMember from '@/composables/getMember'
import { projectFirestore } from '../firebase/config'
import { useRoute, useRouter } from 'vue-router'

export default {
  props: ['id'],
  components: { },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const { error, member, load } = getMember(route.params.id)
    load()

    const handleClick = async (id) => {
      if(confirm("削除しますか?")){
        await projectFirestore.collection('members')
          .doc(id)
          .delete()
        
        router.push({ name: 'Home' })
      }
    }

    return { error, member, handleClick }
  },
}
</script>
<style>

  .memberInfo{
    width: 90%;
    max-width: 900px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: white;
  }

</style>