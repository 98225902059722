<template>
  <div class="members" > 
    <div class="profile-card" v-for="member in documents">
      <div class="mask-shadow"></div>      
      <header>
        <!-- here’s the avatar -->
        <router-link :to="{ name: 'Member', params: { id: member.id }}">
          <a><img :src="member.fileUrl" ></a>
        </router-link>
        <!-- 表示名 -->
        <h1>{{ member.name.lastJa }} {{ member.name.firstJa }}</h1>
      </header>
    </div>

    <router-link :to="{ name: 'AddMember' }" class="float my-float material-icons">
      <span>add</span>
    </router-link>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import getUser from '@/composables/getUser'
import { watch } from 'vue'
import { useRouter } from 'vue-router'


export default {
  name: 'Home',
  components: {},
  setup(){
    const { error, documents } = getCollection('members')
    const { user } = getUser()
    const router = useRouter()

   // if the user value is ever null, redirect to welcome screen
    watch(user, () => {
        if (!user.value) {
        router.push({ name: 'Welcome' })
        }
    })
    return { error, documents, user }

  }
}
</script>



<style>
.members {
    width: 90%;
    max-width: 900px;
    margin: 10px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: white;
}

body {
    background-color: #ffffff;
    padding: 20px 20px 20px;
    font-family: 'Open Sans', sans-serif;
}

/* profile cards */
.profile-card {
    float: left;
    width: 130px;
    height: 165px;
    margin: 40px 5px 15px 5px;
    padding: 20px 10px 10px;
    background: #fff;
    border: 5px solid rgba(255, 255, 255, .7);
    text-align: center;
    border-radius: 4px;
    transition: all 200ms ease;
}


.profile-card header a {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 100%;
    margin: 0px auto 0;
    box-shadow: 0 0 0 3px #edffff;
}

.profile-card header a img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.profile-card:hover header a,
.profile-card header a:hover {
    animation: bounceOut .4s linear;
    -webkit-animation: bounceOut .4s linear;
}

.profile-card header h1 {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 13px;
    color: #444;
    text-transform: uppercase;
    margin-bottom: 1px;
    text-decoration: none;
}



.profile-card header h2 {
    font-size: 12px;
    color: #acacac;
    text-transform: uppercase;
    margin: 0;
}

.profile-card header h3 {
    font-size: 12px;
    color: #acacac;
    text-transform: uppercase;
    margin: 0;

}


.material-icons{
  font-size: 24px;
  color: #41a7c5;
  cursor: pointer;
  margin-right: 5px;
}

.material-icons:hover{
  color: #285B7E;
  cursor: pointer;
}

.material-icons.delete{
  font-size: 24px;
  color: #F8C03F;
  cursor: pointer;
  margin-right: 5px;
}

.material-icons.delete:hover{
  font-size: 24px;
  color: #F59C3A;
  cursor: pointer;
  margin-right: 5px;
}

.material-icons.description{
  font-size: 24px;
  color: #40B5A0;
  cursor: pointer;
  margin-right: 5px;
}

.material-icons.description:hover{
  font-size: 24px;
  color: #37A192;
  cursor: pointer;
  margin-right: 5px;
}


.material-icons.done{
  font-size: 24px;
  color: #E2E2F2;
  cursor: pointer;
  margin-right: 5px;
}
.material-icons.done:hover{
  font-size: 24px;
  color: #111;
  cursor: pointer;
  margin-right: 5px;

}

  .float.material-icons{
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color: #F8C03F;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
  cursor: pointer;
  margin-right: 5px;
  text-decoration: none;
  position:fixed;
	font-size: 55px;
  padding: auto;
  text-decoration: none;
}

</style>