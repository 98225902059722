<template>
  <button class="ngci" @click="handleSocialLogin">    
    <span class="material-icons login">login</span>
    ログイン(ngci.co.jpユーザのみ)
</button>
</template>

<script>
/* ngci.co.jp ドメインのGoogle Auth */
import useSocialLogin from '@/composables/useSocialLogin'

export default {
  setup(context) {
    const { error, socialLogin } = useSocialLogin()
    const handleSocialLogin = async () => {
      await socialLogin()
      if (!error.value) {
        context.emit('login')
      }
    }
    return { handleSocialLogin, error }
  }
}
</script>

<style>
  button.ngci{
    cursor: pointer;
    background-color: #F8C03F;
    border-radius: 10px;
    font-size: 20px;
    margin: 10px;
    padding-right: 20px;
  }

  button.ngci:hover{
    background-color: #F59C3A;
  }
  
  .material-icons.login{
    font-size: 20px;
    color: #FFF;
    text-decoration: none;
  }

</style>