import { ref } from 'vue';
import firebase from 'firebase'

const error = ref(null)

/* Google Authを使ってAuthentificationを管理 */
/* Google Cloud Platform側にてドメインをngci.co.jpのみに制限 */
const socialLogin = async () => {
  const provider = new firebase.auth.GoogleAuthProvider()

  error.value = null

  try {
    const res = await firebase.auth().signInWithRedirect(provider)
    error.value = null
    console.log(res)
    return res
  }
  catch(err) {
    console.log(err.message)
    error.value = 'Incorrect login credentials'
  }  
}

const useSocialLogin = () => {
  return { error, socialLogin };
}



export default useSocialLogin