import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyB-Fnk3LGIWNBMxo9PNxllVLZQgPy7OkoM",
  authDomain: "ngci-members.firebaseapp.com",
  projectId: "ngci-members",
  storageBucket: "ngci-members.appspot.com",
  messagingSenderId: "975592710222",
  appId: "1:975592710222:web:fe1e7c2622d9243d21631f",
  measurementId: "G-ZHKV71SLY5"
};

// init firebase
firebase.initializeApp(firebaseConfig)

const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// init firestore service
const timestamp = firebase.firestore.FieldValue.serverTimestamp

// export firestore
export { projectAuth, projectFirestore, timestamp, projectStorage }